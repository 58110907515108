import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { light, dark } from 'themes';
import breakpoints from 'utils/breakpoints';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import Img from 'components/Img';
import VideoPlayer from 'components/VideoPlayer';
import RoundButton from 'components/Button/Round';
import SectionBody from 'components/About/SectionBody';
import EarthImage from 'components/About/EarthImage';
import ConsumersImage from 'components/About/ConsumersImage';
import TeamMember from 'components/About/TeamMember';
import WaveAnimation from 'components/About/WaveAnimation';
import { HEADER_HEIGHT } from 'components/Header';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import heroContent from 'contents/about-us/hero.json';
import leadershipsContent from 'contents/about-us/leaderships.json';
import videoPoster from 'assets/about/video-poster.png';
import { useScrollTo } from 'utils';

const Intro = styled.section`
  padding-top: ${HEADER_HEIGHT + 52}px;
  background-image: linear-gradient(180deg, #ddeeff 0%, #ffffff 99%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${HEADER_HEIGHT + 96}px;
  }
`;

const StyledWaveAnimation = styled(WaveAnimation)`
  margin-top: -50px;
`;

const CTAButton = styled(RoundButton).attrs({ icon: <ChevronDown /> })`
  display: inline-block;
  margin: 0.6875rem 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 1.25rem 0;
  }
`;

const CenterHeading = styled(Heading).attrs({
  titleClass: 'heading__title--center',
})`
  &.heading__title--center {
    text-align: center;
  }
`;

const OurMission = styled.section`
  background: #ffffff;
  padding: 70px 0;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 5.375rem;
    margin-bottom: 7rem;
    padding: 0;
  }
`;

const OurCoverage = styled.section`
  padding: 2.625rem 0;
  background: #f4f9ff;
`;

const IntroVideo = styled.section`
  padding: 120px 0;
  background: linear-gradient(
    to bottom,
    #0276f1 0%,
    #0276f1 50%,
    #ffffff 50%,
    #ffffff 100%
  );
`;

const LeadershipTeam = styled.section`
  padding: 0 0 11.88rem;
  background: #fff;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; // 16:9

  .player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.5rem;
    overflow: hidden;
  }
`;

const EarthImageWrapper = styled.div`
  width: 140%;
  margin-bottom: 40px;
  margin-left: -25%;

  @media screen and (min-width: ${breakpoints.md}) {
    margin: 0;
    width: 44.13rem;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 63.19rem;
  }
`;

const headingColOffset = { md: 2 };

function AboutPage() {
  const scrollToOurMission = useScrollTo('ourMission');
  const { ceo, team: leadershipTeamMembers } = leadershipsContent;
  return (
    <ThemeProvider theme={light}>
      <Layout fullSize pageTitle="About us">
        <ThemeProvider theme={dark}>
          <Intro>
            <Container>
              <Row>
                <Col md={8} offset={headingColOffset}>
                  <CenterHeading title={heroContent.heading} />
                </Col>
              </Row>
              <CTAButton onClick={scrollToOurMission} />
            </Container>
            <StyledWaveAnimation />
          </Intro>
          <OurMission>
            <Container id="ourMission">
              <Row align="center">
                <Col md={7} push={{ md: 5 }}>
                  <EarthImageWrapper>
                    <EarthImage />
                  </EarthImageWrapper>
                </Col>
                <Col md={5} pull={{ md: 7 }}>
                  <Heading
                    name="Why we founded Trusting Social"
                    title="We empower banks to connect to unbanked consumers"
                  />
                  {heroContent.contents.map(item => (
                    <Paragraph key={item.text}>{item.text}</Paragraph>
                  ))}
                </Col>
              </Row>
            </Container>
          </OurMission>
          <OurCoverage>
            <Container>
              <Row align="center">
                <Col md={6}>
                  <ConsumersImage />
                </Col>
                <Col md={5} offset={{ md: 1 }}>
                  <SectionBody>
                    <Heading
                      name="Our Coverage"
                      title="We are the largest provider of credit risk profiles in Asia"
                    />
                    <Paragraph>
                      Covering more than 1 billion consumers across India,
                      Indonesia, Vietnam and the Philippines.
                    </Paragraph>
                    <Paragraph>
                      This gives financial institutions a unique opportunity to
                      expand their services across new market segments, through
                      unparalleled access to highly reliable risk profile
                      information.
                    </Paragraph>
                  </SectionBody>
                </Col>
              </Row>
            </Container>
          </OurCoverage>
          <IntroVideo>
            <Container>
              <PlayerWrapper>
                <VideoPlayer
                  className="player"
                  url="https://www.youtube.com/watch?v=opvmuDAIfIk"
                  poster={videoPoster}
                />
              </PlayerWrapper>
            </Container>
          </IntroVideo>
          <LeadershipTeam>
            <Container>
              <Row justify="center">
                <Heading title={leadershipsContent.heading} />
              </Row>
              <Row justify="center">
                <Col md={4} lg={3}>
                  <TeamMember
                    {...ceo}
                    image={<Img publicURL={ceo.image} alt={ceo.name} />}
                  />
                </Col>
              </Row>
              <Row justify="center">
                {leadershipTeamMembers.map(member => (
                  <Col sm={6} md={4} lg={4} key={member.name}>
                    <TeamMember
                      {...member}
                      image={<Img publicURL={member.image} alt={member.name} />}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </LeadershipTeam>
        </ThemeProvider>
      </Layout>
    </ThemeProvider>
  );
}

export default AboutPage;
